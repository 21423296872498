import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import _ from 'lodash';

export class PasswordStrengthHelper {
  public static createPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || control.value.trim().length == 0) {
        return { required: true };
      }

      //  Mật khẩu của bạn phải dài 8-20 ký tự,
      const password = control.value;
      if (password.trim().length < 8 || password.trim().length > 20) {
        return { mustMoreThen8Characters: true };
      }

      // chứa chữ cái và số và không được chứa dấu cách
      const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).+/g;
      const exp: RegExp = new RegExp(passwordRegex);
      if (!exp.test(password)) {
        return { containNumberAndCharacters: true };
      }
      return null;
    };
  }

  public static createPasswordStrengthValidatorContainUserName(
    username: string,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Không được phép bằng username
      if (_.toLower(control.value).trim() === _.toLower(username).trim()) {
        return { equalUsername: true };
      }
      return null;
    };
  }
}
