<div class="form-group">

  <label class="form-label" [for]="this.id">{{this.label}} <span class="text-danger"> (*)</span></label>

  <!-- INPUT FORM -->
  <div class="input-group">
    <input [id]="this.id"
           [formControl]="this.inputPasswordControl"
           [placeholder]="this.placeholder"
           [class]="this.class"
           [attr.disabled]="this.disabled ?? false"
           autocomplete="off"
           type="{{ this.showPassword ? 'text' : 'password' }}">

    <div class="input-group-append cursor-pointer" (click)="this.onShowPassword()">
      <span class="input-group-text">
        <i *ngIf="this.showPassword" class="fal fa-eye"></i>
        <i *ngIf="!this.showPassword" class="fal fa-eye-slash"></i>
      </span>
    </div>



    @if(this.setErrorMessage.length !== 0 && this.inputPasswordControl.touched) {
    <div class="invalid-feedback d-block">
      {{ this.setErrorMessage }}
    </div>
    } @else {
    <!-- DISPLAY ERROR -->
    <div class="invalid-feedback d-block"
         *ngIf="this.inputPasswordControl?.hasError('required') && this.inputPasswordControl?.touched">
      Mật khẩu không được để trống
    </div>

      @if(!this.disableCheck) {
    <!-- DISPLAY ERROR -->
    <div class="invalid-feedback d-block" *ngIf="
!this.inputPasswordControl?.hasError('required')
&& this.inputPasswordControl?.hasError('equalUsername')
&& this.inputPasswordControl?.touched
">
      Mật khẩu không được trùng với tên đăng nhập
    </div>


    <div class="invalid-feedback d-block" *ngIf="
this.inputPasswordControl?.hasError('mustMoreThen8Characters')
&& this.inputPasswordControl?.touched
">
      Mật khẩu của bạn phải dài ít nhất 8 và nhiều nhất 20 ký tự
    </div>

    <div class="invalid-feedback d-block" *ngIf="
this.inputPasswordControl?.hasError('containNumberAndCharacters')
&& this.inputPasswordControl?.touched
">
      Mật khẩu của bạn phải chứa chữ cái và số và không được chứa dấu cách
    </div>
    <div class="invalid-feedback d-block" *ngIf="this.matchOldPassword">
      Mật khẩu mới và mật khẩu cũ không được giống nhau
    </div>
    }
    }
  </div>

</div>
