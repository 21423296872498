import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  isDevMode,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { PasswordStrengthHelper } from '@features/auth/shared/helpers/password-strength-validator.helper';
import _ from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.css'],
})
export class InputPasswordComponent implements OnInit, OnDestroy {
  @Input() placeholder: string = '';
  @Input() label: string = 'Mật khẩu';
  @Input() value: string = '';
  @Input() class: string = "form-control";
  @Input() disableCheck: boolean = false;

  @Input() disabled: boolean | undefined = false;
  @Input() disabled$: Subject<boolean> = new Subject();
  @Input() reset$: Subject<boolean> = new Subject();
  // Kiểm tra xem có trùng username không
  @Input() username: string = '';
  @Input() matchOldPassword: boolean = false;

  @Input() setErrorMessage = '';

  public id = 'input_password_' + _.uniqueId();
  @Output() changePassword = new EventEmitter<string>();

  public inputPasswordControl!: FormControl;
  public showPassword = false;

  destroy$$ = new Subject<void>();

  ngOnInit(): void {
    this.inputPasswordControl = new FormControl(this.value, {
      validators: this.username
        ? [
            PasswordStrengthHelper.createPasswordStrengthValidator(),
            PasswordStrengthHelper.createPasswordStrengthValidatorContainUserName(
              this.username,
            ),
          ]
        : PasswordStrengthHelper.createPasswordStrengthValidator(),
    });

    this.inputPasswordControl.valueChanges.subscribe({
      next: (value) => {
        this.value = value;
        this.changePassword.emit(value);
      },
      error: (err) => {
        this.changePassword.error(err);
      },
    });

    this.disabled$.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => {
        if (value) {
          this.inputPasswordControl.disable();
        } else {
          this.inputPasswordControl.enable();
        }
      },
      error: (err) => {
        isDevMode() && console.error(err);
        this.inputPasswordControl.enable();
      },
    });

    this.reset$.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => {
        if (value) {
          this.inputPasswordControl.reset();
        }
      },
      error: (err) => {
        isDevMode() && console.error(err);
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  onShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
